import React from 'react';
import { Link } from 'react-router-dom';

const Pagination = ({ currentPage, totalPages }) => {
  const getPages = () => {
    const pages = [];
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, currentPage + 2);

    if (startPage > 1) {
      pages.push(1);
      if (startPage > 2) pages.push('...');
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) pages.push('...');
      pages.push(totalPages);
    }

    return pages;
  };

  return (
    <div className="pagination">
      {currentPage > 1 && (
        <Link to={`/weblog/page/${currentPage - 1}`} className="pagination-button">
          قبلی
        </Link>
      )}
      {getPages().map((page, index) =>
        typeof page === 'number' ? (
          <Link
            key={index}
            to={`/weblog/page/${page}`}
            className={`pagination-button ${currentPage === page ? 'active' : ''}`}
          >
            {page}
          </Link>
        ) : (
          <span key={index} className="pagination-ellipsis">
            {page}
          </span>
        )
      )}
      {currentPage < totalPages && (
        <Link to={`/weblog/page/${currentPage + 1}`} className="pagination-button">
          بعدی
        </Link>
      )}
    </div>
  );
};

export default Pagination;
