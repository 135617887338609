import { Link, useNavigate } from "react-router-dom";
import "../Css/Post.css";
import { Card } from "primereact/card";
import { LinkedinShareButton, LinkedinIcon, EmailIcon, EmailShareButton, WhatsappShareButton, WhatsappIcon, FacebookShareButton, FacebookIcon } from 'react-share';
import { Toast } from "primereact/toast";
import UseAnimations from 'react-useanimations';
import { Button } from "primereact/button";
import heart from 'react-useanimations/lib/heart';
import { OverlayPanel } from 'primereact/overlaypanel';
import Comment from "./Comment";
import { createRef, useEffect, useRef, useState } from "react";
import { Fieldset } from "primereact/fieldset";
import { SplitButton } from "primereact/splitbutton";
import { ConfirmDialog } from "primereact/confirmdialog";
import axios from "axios";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";

function Article(props) {
    const navigate = useNavigate();
    
    const [commentsCount, setCommentsCount] = useState(props.article.comments?.length);
    const [comments,setComments] = useState([]);
    const [likesCount, setLikesCount] = useState(props.article.likesCount);
    const [isLiked, setIsLiked] = useState(false);
    const toast = createRef();
    const op = useRef();
    const token = JSON.parse(localStorage.getItem('userToken'));
    const config = { headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` } };
    const [theme, setTheme] = useState(props.theme);
    const user = JSON.parse(localStorage.getItem('flickerNetwork') != null) ? JSON.parse(localStorage.getItem('flickerNetwork')) : { username: "", name: "", avatarPic: "profile-index.png" };
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [desktop, setDesktop] = useState(window.matchMedia("(min-width: 768px)").matches);
    const commentsSectionRef = useRef(null); // Create a ref for the comments section

    const editItems = [
        {
            label: "ویرایش",
            icon: "pi pi-pencil",
            command: (e) => {
                navigate("/edit-article/", { state: { article: props.article } });
            },
        },
        {
            label: "حذف",
            icon: "pi pi-times",
            command: (e) => {
                setShowConfirmDelete(true);
            },
        },
    ];


    //like & unlike:
    const like = async () => {
        if (!isLiked) {
            await axios.post(`${process.env.REACT_APP_BACK}/articles/like/${props.article._id}`, config).then(async (response) => {
                if (response.status === 201) {
                    await setIsLiked(!isLiked);
                }
            }).catch((error) => {
                if (error.response.data.statusCode === 401) navigate("/login");
            });
            await setLikesCount(likesCount + 1);
        } else {
            await axios.post(`${process.env.REACT_APP_BACK}/articles/unlike/${props.article._id}`, config).then(async (response) => {
                if (response.status === 201) {
                    await setIsLiked(!isLiked);
                }
            }).catch((error) => {
                if (error.response.data.statusCode === 401) navigate("/login");
            });
            await setLikesCount(likesCount - 1);
        }
    };

    const handleScrollToComments = () => {
        if (commentsSectionRef.current) {
            commentsSectionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };


    //commment form:
  const commentformik = useFormik({
    initialValues: {
      content:""
    },
    onSubmit: (values) => {
      
         
  
          const data = JSON.stringify({content:values.content});

          

          axios
            .patch(process.env.REACT_APP_BACK+"/articles/comment/"+props.article._id, data, config)
            .then((response) => {
              console.log(response);
              
              setCommentsCount(commentsCount+1);
              setComments([...comments, {content:values.content,article_id:props.article._id}]);
              
            })
            .catch((error) => {
              console.log("error",error);
              if(error.response.data.statusCode===401)
              navigate("/login")
            });

            commentformik.resetForm();
            
            
        

        }
        ,validate: (values) => {
          let errors = {};
    
          if (!values.content) {
            errors.name = "نظر خود را بنویسید";
          }
    
          return errors;
        }
        

  });


  //set comments:
  useEffect(() => {

    let c = props.article.comments;

    if(c)
    {    
        let articleId = props.article._id;
        let updatedC = c.map(comment => ({
            ...comment,     
            article_id: articleId  
        }));
        setComments(updatedC);
    }
    else
        setComments([]);

  }
  , []);

    return (
        <div id="article">
            
            <Card className="post" style={{ height: !props.singleArticle ? "600px" : null }}>
                <Toast ref={toast} position="top-center" />
                <ConfirmDialog
                    className={theme}
                    visible={showConfirmDelete}
                    onHide={() => setShowConfirmDelete(false)}
                    message="آیا از حذف این مقاله مطمئنید؟"
                    header="تایید حذف"
                    icon="pi pi-info-circle"
                    acceptLabel="بله"
                    rejectLabel="نه"
                    accept={() => props.deleteArticleFromDB(props.article._id)}
                    reject={() => setShowConfirmDelete(false)}
                />


                
                

                <div id="post-header">
                    <span id="post-col1" style={{ width: "10%" }}>
                        {!props.postOfOther && (
                            <SplitButton
                                id={theme}
                                style={{ direction: "rtl" }}
                                label=""
                                model={editItems}
                            ></SplitButton>
                        )}
                        <Toast ref={toast} position="top-center" />
                    </span>
                    <span id="post-col2" style={{ width: '90%' }}>
                        {!props.singleArticle 
                            ?  <h1 style={{ marginBottom: '0px', fontSize: '16px', cursor: 'auto',wordWrap:'anywhere' }}>{props.article.title.substring(0,50)}</h1>
                            : null
                        }
                    </span>
                </div>
                <div id="post-cat">
                    <span className="cat-col1"></span>
                    <span className="cat-col2"></span>
                </div>
                <div id="post-pic">
                    {props.article.indexedImage !== "" && (
                        <img
                            style={{ height: !props.singleArticle ? "250px" : null }}
                            src={`${process.env.REACT_APP_BACK}/articles/${props.article.indexedImage}`}
                            alt={props.article.title+"- فلیکا، شبکه اسب دوستان"}
                            onClick={() => navigate("/weblog/" + props.article._id)}
                        />
                    )}
                </div>
                <div id="post-reaction">
                    <span className={"react-col1 heart-icon-false"}>
                        <Button
                            tooltip="لایک"
                            tooltipOptions={{ className: "create-post-tooltip", position: "top" }}
                        >
                            <UseAnimations
                                className="animated-icon"
                                reverse={false}
                                onClick={like}
                                strokeColor={'#25252580'}
                                fillColor={"#EF4444"}
                                animation={heart}
                                size={30}
                                wrapperStyle={{ padding: 0 }}
                            />
                            <span>{likesCount}</span>
                        </Button>
                        <Button
                            tooltip="نظرات"
                            tooltipOptions={{ className: "create-post-tooltip", position: "top" }}
                            onClick={handleScrollToComments}
                        >
                            <i className="pi pi-comment"></i>
                            <span>{commentsCount}</span>
                        </Button>
                        <Button
                            tooltip="به اشتراک گذاری"
                            tooltipOptions={{ className: "create-post-tooltip", position: "top" }}
                            onClick={(e) => { op.current.toggle(e) }}
                        >
                            <i className="pi pi-share-alt"></i>
                            <span></span>
                        </Button>
                        <OverlayPanel ref={op} dismissable className={theme}>
                            <div>
                                <Button
                                    tooltip="کپی لینک"
                                    tooltipOptions={{ className: "create-post-tooltip", position: "top" }}
                                    onClick={() => {
                                        navigator.clipboard.writeText("https://flicka.ir/posts/" + props.article._id);
                                        toast.current.show({ severity: 'success', detail: 'لینک در حافظه کپی شد' });
                                    }}
                                    style={{ padding: "6px", marginBottom: "8px" }}
                                >
                                    <i className="pi pi-link"></i>
                                    <span></span>
                                </Button>
                            </div>
                            <div>
                                <LinkedinShareButton
                                    url={"http://flicka.ir/posts/" + props.article._id}
                                    title="یه پست جذاب از فلیکا"
                                    summary={props.article.title}
                                >
                                    <LinkedinIcon style={{ opacity: '1' }} size={28} round={true} />
                                </LinkedinShareButton>
                            </div>
                            <div>
                                <WhatsappShareButton
                                    url={"http://flicka.ir/posts/" + props.article._id}
                                    title="یه پست جذاب از فلیکا"
                                    summary={props.article.title}
                                >
                                    <WhatsappIcon style={{ opacity: '1' }} size={28} round={true} />
                                </WhatsappShareButton>
                            </div>
                            <div>
                                <FacebookShareButton
                                    url={"http://flicka.ir/posts/" + props.article._id}
                                    title="یه پست جذاب از فلیکا"
                                    summary={props.article.title}
                                >
                                    <FacebookIcon style={{ opacity: '1' }} size={28} round={true} />
                                </FacebookShareButton>
                            </div>
                            <EmailShareButton
                                url={"http://flicka.ir/posts/" + props.article._id}
                                title="یه پست جذاب از فلیکا"
                                summary={props.article.title}
                            >
                                <EmailIcon style={{ opacity: '1' }} size={28} round={true} />
                            </EmailShareButton>
                        </OverlayPanel>
                    </span>
                    <span className="react-col2 save-icon" style={{ textAlign: "left" }}>
                        {new Date(props.article.createdDate).toLocaleDateString('fa-IR')}
                    </span>
                </div>
                
                <div id="post-content">
                    <div
                        style={!props.singleArticle ? {
                            display: '-webkit-box',
                            WebkitLineClamp: '3',
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        } : null}
                        dangerouslySetInnerHTML={{
                            __html: !props.singleArticle
                                ? props.article.metaDescription + " . . . "
                                : props.article.content
                        }}
                    />
                    {!props.singleArticle && (
                        <p className="view-more-button"
                            style={{ cursor: 'pointer' }}
                            onClick={() => navigate("/weblog/" + props.article._id)}
                        >
                            بیشتر بخوانید ...
                        </p>
                    )}
                </div>
            </Card>
            
            
            {props.singleArticle && (
                <div id="comments-section" ref={commentsSectionRef}>
                    <h3>دیدگاهتان را بنویسید</h3>
                    
                    <div id="comments">
                        
                        <div id="new-comment">
                            
                            <span className="row1">
                            
                            <img src={`${process.env.REACT_APP_BACK}/avatars/profile-index.png`}
                            alt={`فلیکا ، شبکه اجتماعی سوارکاری `}
                            ></img>
                            
                            </span>
                            <span className="row2 search-box-wraper">

                            <form onSubmit={commentformik.handleSubmit}>
                            
                            <InputText
                                id="content"
                                name="content"
                                className="search-box"
                                value={commentformik.values.content}
                                placeholder="نظر خود را بنویسید"
                                onChange={commentformik.handleChange}
                            />
                            </form>
                            <a style={{color:"#af734f", marginLeft:"8px"}} onClick={commentformik.handleSubmit}>ارسال</a>

                            </span>


                        </div>

                    </div>
                    {!comments || comments?.length === 0 ?
                        <p style={{marginTop:"50px"}}>تاکنون نظری ثبت نشده است.</p>
                        : 

                    
                            <div id="comments" style={{margin:"50px 50px 0px 50px" }}>

                                
                                {comments?.reverse().map((comment, index) => (
                                    <div key={index}>
                                        
                                        <Comment
                                            comment={comment}
                                            isArticle={true}
                                            replies={[]}
                                            decrementCommentsCount={(content) => {
                                                setCommentsCount(commentsCount - 1);
                                                setComments(prevComments => 
                                                    prevComments.filter(comment => comment.content !== content)
                                                  );

                                            }
                                            }
                                            nestedReply={false}
                                            showViewMore={true}
                                        />
                                    </div>
                                ))}
                            </div>
                        
                    }


                </div>




            )}
        </div>
    );
}

export default Article;
