import { InputText } from "primereact/inputtext";
import "../Css/Weblog.css";
import { useState , useEffect} from "react";
import SuggestionRow from "./SuggestionRow";
import Tag from "./Tag";
import { Link, useAsyncError, useNavigate } from "react-router-dom";
import axios from "axios";


function WeblogSidebar2()
{

    const [searchValue,setSearchValue] = useState("");
    const [allArticles,setAllArticles] = useState([]);
    const [showSearchbox,setShowSearchbox] = useState(false);
    const [searchArticlessLoading,setSearchArticlesLoading] = useState(false);
    const [fetchedtags,setFetchedTags] = useState([]);
    const [fetchedArticles,setFetchedArticles] = useState([]);
    const token = JSON.parse(localStorage.getItem('userToken'));
    const config = {  headers: {"Content-Type": "application/json",Authorization: `Bearer ${token}`} };
    const navigate = useNavigate();

    


      //fetching popular tags***********************************************************************************************
      useEffect(() => {
      
        async function fetchTags() {
                       
            axios
            .get(process.env.REACT_APP_BACK+"/posts/popular_tags/", config)
            .then((result) => {
                if (result.status == 200) {
                setFetchedTags(result.data);
                console.log("fetched Popular Tags: ", result.data);
                return result.data;
                }
            })
            .catch((error) => {
                console.log(error);
                if(error.response.data.statusCode===401)
                    navigate("/login")
            });
        
        }

    fetchTags();

  }, []);


  
//related articles*********************************************************************************************************

  useEffect(() => {

      async function fetchArticles() {
          
          axios
          .get(process.env.REACT_APP_BACK+"/articles/all/"+3, config)
          .then((result) => {
              if (result.status == 200) {
              setFetchedArticles(result.data);
              
              return result.data;
              }
          })
          .catch((error) => {
              console.log(error);
              if(error.response.data.statusCode===401)
                  navigate("/login")
          });
      
      }

  fetchArticles();
}, []);


//search*************************************************************************************************************************8
const handleSearch = (event) => {
    let { value } = event.target;
    
    setSearchValue(value);
    setShowSearchbox(true);
    if(value==="")
    {
      setAllArticles([]);
    }
  };
  // Create a debounced version of the API request
    useEffect(() => {
    
        const delayedApiRequest = setTimeout(() => {
        if (searchValue) {
            fetchForSearch(searchValue.replace(/\s+/g,"_"));
        }
        }, 500);
    
        
        return () => clearTimeout(delayedApiRequest);
    }, [searchValue]);



    async function fetchForSearch(searchValue) {

        let heightIndex = 1;
        let offset = 10;
      
      
        await setAllArticles([]);
        await setSearchArticlesLoading(true);
        
        await axios.get(process.env.REACT_APP_BACK+"/articles/search/"+searchValue+"/"+offset,config)
      
          .then((result) => {
      
            if (result.status == 200) {
              setAllArticles(result.data);
              setSearchArticlesLoading(false);
              console.log("fetched searched articles: ", result.data);
              return result.data;
            }
          })
          .catch((error) => {
            console.log(error);
            if(error.response.data.statusCode===401)
                   navigate("/login")
          });
      
      
      
          const dialogElement = document.querySelector('#searchbox');
          if (dialogElement) {
           
      
            dialogElement.addEventListener('scroll', async function() {
      
             
          
            let scrollPosition = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;  
            let visibleHeight = document.documentElement.clientHeight;    // Get the visible height of the screen
            let distanceScrolled = scrollPosition + visibleHeight;        // Calculate the distance the user has scrolled from the top of the page
      
      
            if (distanceScrolled >= (66.6*heightIndex) ) {                 // Check if the user has scrolled down a certain amount
                
              
              heightIndex += 1;
                
          //fetch next:
          if(heightIndex === (offset))
          {
            offset += 10;
            await setSearchArticlesLoading(true);
      
            await axios
            .get(process.env.REACT_APP_BACK+"/articles/search/"+searchValue+"/"+offset, config)
            .then((result) => {
              if (result.status == 200) {
      
                setAllArticles(prevArray => [...prevArray, ...randomise(result.data)]); 
                console.log("fetched searched articles: ", result.data);
                
                
                return result.data;
              }
            })
            .catch((error) => {
              console.log(error);
              if(error.response.data.statusCode===401)
                    navigate("/login")
            });
              
          
      
            setSearchArticlesLoading(false);
            
            
      
          }
      
         
          }
        });
          }
      
      }

      const randomise = (r) =>
        {
               //Fisher-yates shuffle algorithm:
               for(let i=r.length-1; i>0; i--)
               {
                  const j = Math.floor(Math.random()*(i+1));
                  [r[i],r[j]] = [r[j],r[i]];
               }
      
               return r;
        }
      


    return(
        <div id="weblogsidebar2" >

            <div className="no-scrol sidebar_container">

            <div id="searchSection">

            <span className="p-input-icon-right search-box-wraper">
            <i className="pi pi-search" />
            <InputText
              className="search-box"
              placeholder="اینجا جستجو کنید ..."
              value={searchValue}
              onChange={handleSearch}
            />
          </span>

            

            <div id="searchbox" style={{display: searchValue!=="" ?  "block" : "none", marginTop:"0px"}}>

                    {/* load animation */}
                    {searchArticlessLoading ? 
                        <div className="spinner" style={{marginBottom:"50px"}}></div>
                    : null}

                
            {allArticles.length>0 && showSearchbox && (

            <ul>

                    
                    {allArticles.map((article, index) => {
                        return (
                        <div className={"user" + index} key={index}>
                            <div id="suggestion-box" className="search-row" style={{display:"flex",direction:"rtl",alignItems:"center"}}
                            onClick={()=>navigate("/weblog/"+article._id)}>
                           
                           <span style={{display:"flex",direction:"rtl",alignItems:"center"}}>
                           <img style={{borderRadius:'10px'}} src={`${process.env.REACT_APP_BACK}/articles/${article.indexedImage}`}></img>
                           <p style={{marginRight:"20px", fontSize:"13px"}}>{article.title.substring(0,100)}</p>
                           
                           </span>
                           
                            </div>
                        </div>

                        
                        );
                        
                    })}

                    

                    

            </ul>


            )}

            {allArticles.length===0 && showSearchbox ?<span style={{marginTop:"20px"}}>  نتیجه ای یافت نشد </span>: null}

            </div>

            </div>





            <div id="otherArticlesSection">

                    <h3 className="title">مقاله های بیشتر </h3>

                    {fetchedArticles.map((article, index) => (
                

                        <Link  key={index} className="other_articles" to={"/weblog/"+article._id} >
                        <li style={{marginBottom:'20px'}}>
                            <div>
                                <img 
                                    src={`${process.env.REACT_APP_BACK}/articles/${article.indexedImage}`}
                                    alt="پستی از فلیکا، شبکه اجتماعی سوارکاری"
                                ></img>
                                <h3>{article.title}</h3>
                                <p>{article.metaDescription+" ..."}</p>
                            </div>
                        </li>
                        </Link>

                    ))}

            </div>

            <div id="tagsSection">

            <h3 className="title">برچسب ها</h3>
                
                
                <div className="tags">
                <ul className="section1">

                
                {fetchedtags.map((tag, index) => (
                
                    

                    <Link  key={index} to={"/tags/"+tag.value}>
                    <li>
                        <Tag value={tag.value}></Tag>
                    </li>
                    </Link>

                ))}

                </ul>

                </div>

                <div>
        <button 
            style={{margin:"50px 30px 20px 30px"}} 
            className="login-button"
            onClick={()=>navigate("/tags")}
            >
                مشاهده همه تگ ها</button>
          
          </div>

            </div>

            </div>

        </div>
    );
}

export default WeblogSidebar2;