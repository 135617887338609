import axios, { Axios } from "axios";
import { Column } from "primereact/column";
import { ConfirmDialog } from "primereact/confirmdialog";
import { ContextMenu } from "primereact/contextmenu";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";


function ManageTags(props)
{

    const [tags,setTags] = useState([]);
    const [updateTags,setUpdateTags] = useState(0);
    const [selectedTag, setSelectedTag] = useState({value:"",count:""});
    const cm = useRef(null);
    const toast = useRef(null);
    const token = JSON.parse(localStorage.getItem('userToken'));
    const config = {  headers: {"Content-Type": "application/json",Authorization: `Bearer ${token}`} };
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const navigate = useNavigate();
    
    
    const menuModel = [
        {label: 'حذف', icon: 'pi pi-fw pi-trash', command: () => setShowConfirmDelete(true)}
    ];


    const deleteTagFromDB = async (value) => 
    {
      const encodedValue = encodeURIComponent(value);

      await axios.delete(process.env.REACT_APP_BACK+"/posts/delete-tag/" + encodedValue, config)
      .then(response => 
        {
          if (response.status == 200)
          {
            console.log(response);      
            toast.current.show({severity: 'info', summary: 'برچسب حذف شد'});
            setUpdateTags(updateTags+1);    
            
          }

      })
      .catch(error => {
         console.log(error);
         if(error.response.data.statusCode===401)
              navigate("/login")
      });
    };

   

    //get all tags from database:
    useEffect(() => {
    async function getAllTags() {

      
      const result = await axios.get(process.env.REACT_APP_BACK+"/posts/tags", config)
      .then((result) => {
        if (result.status == 200) {
          setTags(result.data);
          console.log("all tags",result.data);
          return result.data;
        }
      })
      .catch((error) => {
        console.log(error);
        if(error.response.data.statusCode===401)
              navigate("/login")
      });
      return result.data;

    }
    getAllTags();
  },[updateTags]);

  


    

    return(

        <div id="manage-pages">

            <h2>
            مدیریت برچسب ها
            </h2>

            <Toast ref={toast}></Toast>

            <ContextMenu model={menuModel} ref={cm} />

            <DataTable size="normal" value={tags} paginator rows={20} editMode="row"
            className="manager-table"
            contextMenuSelection={selectedTag}
            onContextMenuSelectionChange={e => setSelectedTag(e.value)}
            onContextMenu={e => cm.current.show(e.originalEvent)}>
            
            
            <Column alignHeader="center" columnKey="value" align="center" field="value" header="نام برچسب" filter sortable  ></Column>
            <Column alignHeader="center" columnKey="count" align="center" field="count" header="تعداد استفاده" sortable ></Column>
    
            
            
            </DataTable>


            <ConfirmDialog 
            className={props.theme}
            visible={showConfirmDelete} 
            onHide={() => setShowConfirmDelete(false)} 
            message="آیا از حذف این برچسب مطمئنید؟"
            header="تایید حذف"
            icon="pi pi-info-circle"
            acceptLabel="بله"
            rejectLabel="نه"
            acceptClassName="accept"
            rejectClassName="reject"
            accept={() => deleteTagFromDB(selectedTag.value)} 
            reject={() => setShowConfirmDelete(false)} />

           

        </div>
    );
}
  
export default ManageTags;