import "../Css/NewPost.css";
import { Dropdown } from "primereact/dropdown";
import { useFormik } from "formik";
import React, { useRef, useState } from "react";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import "../Css/VideoImageUploader.css";
import Chip from "./Chip";
import { useNavigate } from "react-router-dom";
import { Editor } from 'primereact/editor';
import { InputText } from "primereact/inputtext";
import axios from "axios";

const token = JSON.parse(localStorage.getItem('userToken'));
const config = { headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` } };
const user = JSON.parse(localStorage.getItem('flickerNetwork')) != null ? JSON.parse(localStorage.getItem('flickerNetwork')) : { username: "", name: "", avatarPic: "profile-index.png" };

function NewArticle(props) {
    const [uploadFile, setUploadFile] = useState("");
    const [selectedRiderPr, setSelectedRiderPr] = useState([]);
    const [selectedHorsePr, setSelectedHorsePr] = useState([]);
    const [fileType, setFileType] = useState();
    const inputRef = React.useRef();
    const [source, setSource] = useState();
    const [showTags, setShowTags] = useState(false);
    const [showTagUsers, setShowTagUsers] = useState(false);
    const [desktop, setDesktop] = useState(window.matchMedia("(min-width: 768px)").matches);
    const [clickedButton, setClickedButton] = useState('');
    const [isHtmlMode, setIsHtmlMode] = useState(false);
    const [htmlContent, setHtmlContent] = useState(""); 
    const navigate = useNavigate();
    const [uploadProgress, setUploadProgress] = useState(0);
    const editorRef = useRef(null);
    const colors = [
        'rgb(0, 0, 0)', 'rgb(230, 0, 0)', 'rgb(255, 153, 0)', 'rgb(255, 255, 0)', 'rgb(0, 138, 0)',
        'rgb(0, 102, 204)', 'rgb(153, 51, 255)', 'rgb(255, 255, 255)', 'rgb(250, 204, 204)',
        'rgb(255, 235, 204)', 'rgb(255, 255, 204)', 'rgb(204, 232, 204)', 'rgb(204, 224, 245)',
        'rgb(235, 214, 255)', 'rgb(187, 187, 187)', 'rgb(240, 102, 102)', 'rgb(255, 194, 102)',
        'rgb(255, 255, 102)', 'rgb(102, 185, 102)', 'rgb(102, 163, 224)', 'rgb(194, 133, 255)',
        'rgb(136, 136, 136)', 'rgb(161, 0, 0)', 'rgb(178, 107, 0)', 'rgb(178, 178, 0)', 'rgb(0, 97, 0)',
        'rgb(0, 71, 178)', 'rgb(107, 36, 178)', 'rgb(68, 68, 68)', 'rgb(92, 0, 0)', 'rgb(102, 61, 0)',
        'rgb(102, 102, 0)', 'rgb(0, 55, 0)', 'rgb(0, 41, 102)', 'rgb(61, 20, 102)'
    ];

    const createColorOptions = (colors) => colors.map(color => (
        <option key={color} value={color} style={{ backgroundColor: color }}></option>
    ));

    //supporting webp:
    const imageHandler = () => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();

        input.onchange = async () => {
            const file = input.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const quill = editorRef.current.getQuill();
                    const range = quill.getSelection();
                    quill.insertEmbed(range.index, 'image', e.target.result);
                };
                reader.readAsDataURL(file);
            }
        };
    };

    const toggleMode = () => {
        if (isHtmlMode) {
            // Update Formik content with HTML content when switching to WYSIWYG mode
            formik.setFieldValue('content', htmlContent);
        }
        setIsHtmlMode(!isHtmlMode);
    };

    const handleHtmlChange = (e) => {
        setHtmlContent(e.target.value);
        // Update Formik content directly when typing in HTML mode
        formik.setFieldValue('content', e.target.value);
    };

    const toolbarOptions = (
        <div id="toolbar">
           
            <select className="ql-header" defaultValue="">
                <option value="1">h1</option>
                <option value="2">h2</option>
                <option value="3">h3</option>
                <option value="">p</option>
            </select>
            <button className="ql-bold"></button>
            <button className="ql-code"></button>
            <button className="ql-italic"></button>
            <button className="ql-underline"></button>
            <select className="ql-align">
                <option value="">Left</option>
                <option value="center">Center</option>
                <option value="right">Right</option>
            </select>
            <button className="ql-strike"></button>
            <button className="ql-list" value="ordered"></button>
            <button className="ql-list" value="bullet"></button>
            <button className="ql-link"></button>
            <button className="ql-image"></button>
            <select className="ql-color">{createColorOptions(colors)}</select>
            <select className="ql-background">{createColorOptions(colors)}</select>
        </div>
    );

   
    

    const buttonStyle = {
        background: `linear-gradient(to right, #af734f ${props.progress}%, #e0e0e0 ${props.progress}%)`,
        paddingLeft: "70px",
        paddingRight: "70px"

    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setUploadFile(file);
        if (file.type.includes("image")) setFileType("image");
        else if (file.type.includes("video")) setFileType("video");
        const url = URL.createObjectURL(file);
        setSource(url);
    };

    const handleChoose = (event) => {
        inputRef.current.click();
    };

    const formik = useFormik({
        initialValues: {
            title: "",
            content: "",
            category: "",
            file: "",
            riderPr: "",
            horsePr: "",
            metaDescription:"",
            keyword:""
        },
        validate: (values) => {
            let errors = {};
            if (!values.title) {
                errors.title = "لطفا تیتر مقاله را وارد کنید";
            }
            if (!values.file && clickedButton==="publish") {
                errors.file = "لطفا عکس شاخص مقاله را وارد کنید";
            }
            if (!values.content) {
                errors.content = "لطفا محتوای مقاله را وارد کنید";
            }
            if (!values.metaDescription && clickedButton==="publish") {
                errors.metaDescription = "لطفا توضیحات متا را وارد کنید";
            }
            if (!values.keyword) {
                errors.keyword = "لطفا کلمه کلیدی را وارد کنید";
            }
            
            
            return errors;
        },
        onSubmit: (values) => {
            values["riderPr"] = selectedRiderPr.map(element => element._id.trim());
            values["horsePr"] = selectedHorsePr.map(element => element._id.trim());

        
            if(!htmlContent)
            {
                // Add alt text to images
                const contentWithAlt = addAltToImages(values.content);
                values["content"] = contentWithAlt;
                
            }
            else
            {
                // Add alt text to images
                const contentWithAlt = addAltToImages(htmlContent);
                values["content"] = contentWithAlt;
            }
            
                submitPost(uploadFile, values);
                return;
        },
    });

    const isFormFieldValid = (phone) => !!(formik.touched[phone] && formik.errors[phone]);
    const getFormErrorMessage = (phone) => {
        return (
            isFormFieldValid(phone) && (
                <p className="p-error" style={{ textAlign: 'right' }}>{formik.errors[phone]}</p>
            )
        );
    };

    const addAltToImages = (htmlString) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, 'text/html');

        doc.querySelectorAll('img').forEach((img, index) => {
            if (!img.hasAttribute('alt')) {
                img.setAttribute('alt', formik.values.title+"- فلیکا، شبکه اسب دوستان");
            }
        });

        return doc.body.innerHTML;
    };

    // Submit new post:
    const submitPost = (uploadFile, values) => {


        // if clickedButton==="publish":
        if(clickedButton==="publish")
        {
                    // Uploading file
                    let formData = new FormData();
                    formData.append("file", uploadFile);

                    const c = {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    };

                    axios
                        .post(process.env.REACT_APP_BACK + "/articles/uploadFile", formData, {
                            c,
                            onUploadProgress: (progressEvent) => {
                                const progress = (progressEvent.loaded / progressEvent.total) * 100;
                                console.log("progresssssssssss",progress);
                                setUploadProgress(progress);
                            },
                            onDownloadProgress: (progressEvent) => {

                            },
                        })
                        .then((res) => {
                            // Now do what you want with the response;
                            var data = {
                                title: values.title,
                                content: values.content,
                                indexedImage: res.data.fileName,
                                creator: user,
                                riderProductsID: values.riderPr,
                                horseProductsID: values.horsePr,
                                metaDescription:values.metaDescription,
                                keyword:values.keyword,
                                productKeyword:values.productKeyword
                            };

                            data = JSON.stringify(data);

                            // Creating article in database
                            axios
                                .post(process.env.REACT_APP_BACK + "/articles", data, config)
                                .then((response) => {
                                    console.log("response", response);

                                    // Navigate:
                                    navigate("/manager-of-flicka/weblog");

                                })
                                .catch((error) => {
                                    console.log("error", error);
                                    if (error.response.data.statusCode === 401)
                                        navigate("/login")
                                });
                        })
                        .catch((error) => {
                            if (error.response.data.statusCode === 401)
                                navigate("/login")
                        });

        }
        //ifclickedButton === "complete_and_publish":
        else if(clickedButton === "complete_and_publish")
        {

            var data = {
                title: values.title,
                content: values.content,
                creator: user,
                keyword:values.keyword,
                productKeyword:values.productKeyword
            };

            data = JSON.stringify(data);

            // Creating article in database
            axios
                .post(process.env.REACT_APP_BACK + "/articles/auto_complete", data, config)
                .then((response) => {
                    console.log("response", response);

                    // Navigate:
                    navigate("/manager-of-flicka/weblog");

                })
                .catch((error) => {
                    console.log("error", error);
                    if (error.response.data.statusCode === 401)
                        navigate("/login")
                });

        }
    }

    return (
        <div id="new_article">
            <form onSubmit={formik.handleSubmit} className="new-post-form">
                <div>
                    <h2 style={{ marginBottom: '50px' }}>مقاله جدید</h2>

                    {getFormErrorMessage("title")}
                    <div style={{ direction: 'rtl', marginBottom: '30px', alignItems: 'center' }}>
                        <span style={{ display: "flex", width: "fit-content" }}>
                            عنوان مقاله :
                        </span>
                        <InputText
                            style={{ display: "flex", width: "100%" }}
                            id="title"
                            name="title"
                            placeholder="تیتر مقاله ..."
                            value={formik.values.title}
                            onChange={formik.handleChange}
                            maxLength={70}
                        />
                    </div>

                    

                    {getFormErrorMessage("keyword")}
                    <div style={{ direction: 'rtl', marginBottom: '30px', alignItems: 'center' }}>
                        <span style={{ display: "flex", width: "fit-content" }}>
                             کلمه کلیدی :
                        </span>
                        <InputText
                            style={{ display: "flex", width: "100%" }}
                            id="keyword"
                            name="keyword"
                            placeholder=" کلمه کلیدی ..."
                            value={formik.values.keyword}
                            onChange={formik.handleChange}
                            maxLength={50}
                        />
                    </div>

                    {getFormErrorMessage("metaDescription")}
                    <div style={{ direction: 'rtl', marginBottom: '30px', alignItems: 'center' }}>
                        <span style={{ display: "flex", width: "fit-content" }}>
                            توضیحات متا:
                        </span>
                        <textarea
                            style={{ display: "flex", width: "100%" }}
                            id="metaDescription"
                            name="metaDescription"
                            placeholder="توضیحات متا ..."
                            value={formik.values.metaDescription}
                            onChange={formik.handleChange}
                            maxLength={150}
                        />
                    </div>

                    {getFormErrorMessage("productKeyword")}
                    <div style={{ direction: 'rtl', marginBottom: '30px', alignItems: 'center' }}>
                        <span style={{ display: "flex", width: "fit-content" }}>
                           کلمه کلیدی برای محصولات مرتبط:
                        </span>
                        <InputText
                            style={{ display: "flex", width: "100%" }}
                            id="productKeyword"
                            name="productKeyword"
                            placeholder="این کلمه برای پیدا کردن محصولات مرتبط استفاده می شود ..."
                            value={formik.values.productKeyword}
                            onChange={formik.handleChange}
                            maxLength={50}
                        />
                    </div>

                    {getFormErrorMessage("file")}
                    <div className="media-upload">
                        <div className="VideoInput">
                            <input
                                id="file"
                                name="file"
                                hidden={true}
                                ref={inputRef}
                                className="VideoInput_input"
                                type="file"
                                onChange={(e) => {
                                    handleFileChange(e);
                                    formik.setFieldValue("file", e.currentTarget.files[0]);
                                }}
                                accept=".jpg, .png, .jpeg"
                            />
                            <span id="upload-buttons">
                                <span style={{ display: "flex", width: "60%", alignItems: "center" }}>
                                    <i className="pi pi-image"></i>
                                    <p onClick={handleChoose}>آپلود عکس شاخص</p>
                                </span>
                                <span style={{ display: "flex", width: "40%", direction: "ltr", alignItems: "center" }}>
                                    <p id="delete-button" onClick={() => setSource(null)}><i className="pi pi-times" />حذف</p>
                                </span>
                            </span>
                            {source && (
                                <img
                                    className="VideoInput_video"
                                    width="100%"
                                    height="100%"
                                    src={source}
                                    
                                />
                            )}
                        </div>
                    </div>
                    <span id="content">

                    <div style={{background:"#f8f9fa",textAlign:'right' , marginRight:'10px' , marginBottom:'10px'}} >
                    <a
                        style={{fontSize:"14px"}}
                        onClick={toggleMode}>
                        {isHtmlMode ? "ادیتور متنی" : "کد html"}</a></div>

                    {!isHtmlMode ? (
                        <Editor
                        ref={editorRef}
                        headerTemplate={toolbarOptions}
                        id="content"
                        name="content"
                        value={formik.values.content}
                        onTextChange={(e) => formik.setFieldValue('content', e.htmlValue)}
                        modules={{
                            toolbar: {
                                container: "#toolbar",
                                handlers: {
                                    image: imageHandler
                                }
                            }
                        }}
                        style={{ height: '500px' }}
                        placeholder="محتوای مقاله ..."
                        rows={1}
                        cols={3}
                    />
                    ) : (
                        <textarea
                            id="htmlContent"
                            name="htmlContent"
                            value={htmlContent}
                            onChange={handleHtmlChange}
                            style={{ width: '100%', height: '500px' }}
                        />
                    )}
                      
                        {/* <Editor
                            ref={editorRef}
                            headerTemplate={toolbarOptions}
                            id="content"
                            name="content"
                            value={formik.values.content}
                            onTextChange={(e) => formik.setFieldValue('content', e.htmlValue)}
                            modules={{
                                toolbar: {
                                    container: "#toolbar",
                                    handlers: {
                                        image: imageHandler
                                    }
                                }
                            }}
                            style={{ height: '500px' }}
                            placeholder="محتوای مقاله ..."
                            rows={1}
                            cols={3}
                        /> */}




                        {getFormErrorMessage("content")}
                        <div className="tag-user" style={{ marginTop: "20px", direction: "rtl", textAlign: "right", display: desktop ? "flex" : "block", alignItems: "center" }}>
                            <span style={{ display: "flex", width: desktop ? "30%" : "100%", alignItems: 'center', cursor: 'pointer', color: '#af734f' , marginBottom: !desktop ? "15px" : null  }} onClick={() => { setShowTagUsers(false); setShowTags(!showTags); }}>
                                <i className="pi pi-tag" style={{ marginLeft: '10px' }}></i>محصولات سوار
                            </span>

                            <span style={{ display: "flex", width: desktop ? "30%" : "100%", alignItems: 'center', cursor: 'pointer', color: '#af734f' }} onClick={() => { setShowTags(false); setShowTagUsers(!showTagUsers); }} >
                                <i className="pi pi-user" style={{ marginLeft: '10px' }}></i>محصولات اسب
                            </span>

                            <span style={{ display: "flex", direction: desktop ? 'ltr' : 'rtl', width: desktop ? "30%" : "100%" , marginTop: !desktop ? "20px" : null}}>
                                <span>
                                    <Button id={((props.progress === 0 || !props.progress) && !desktop) || desktop ? "is-not-uploading" : "is-uploading"}
                                        disabled={(props.progress !== 0 && props.progress) && !desktop ? true : false}
                                        type="submit"
                                        style={buttonStyle}
                                        onClick={() => setClickedButton("publish")}
                                        >انتشار</Button>
                                </span>
                            </span>

                            <span style={{ display: "flex", direction: desktop ? 'ltr' : 'rtl', width: desktop ? "25%" : "100%" , marginTop: !desktop ? "20px" : null }}>
                                <span>
                                    <Button
                                        type="submit"
                                        style={{background:"transparent" , color:"#af734f"}}
                                        onClick={() => setClickedButton("complete_and_publish")}
                                        >تکمیل خودکار مقاله</Button>
                                </span>
                            </span>

                            
                        </div>

                        <div>
                            <div id="tags-area" style={{ height: !showTags ? "0px" : null }}>
                                <h6 style={{ height: "20%", textAlign: "right", marginBottom: "0px", marginTop: "20px" }}>
                                    :محصولات سوار مرتبط
                                </h6>
                                <Chip
                                    id="riderPr"
                                    inputId="riderPr"
                                    name="riderPr"
                                    type="riderPr"
                                    setSelections={setSelectedRiderPr}
                                />
                            </div>
                            <div id="tag-users-area" style={{ height: !showTagUsers ? "0px" : null }}>
                                <h6 style={{ height: "20%", textAlign: "right", marginBottom: "0px", marginTop: "20px" }}>
                                    :محصولات اسب مرتبط
                                </h6>
                                <Chip
                                    id="horsePr"
                                    inputId="horsePr"
                                    name="horsePr"
                                    type="horsePr"
                                    setSelections={setSelectedHorsePr}
                                />
                            </div>
                        </div>

                        <div style={{textAlign:'right' , direction:  'rtl' , fontSize:'14px' , color:'#000000', marginTop:'20px'}}><p>
                            * درصورت انتخاب گزینه "تکمیل خودکار مقاله" ، سیستم بصورت خودکار محتوا را سئو می کند و عکس و محصولات مناسب به آن اضافه می کند.(فقط تیتر مقاله، کلمه کلیدی و محتوای متنی را وارد کنید)
                            </p></div>
                       
                    </span>

                </div>

            </form>


        </div>
    );
}

export default NewArticle;
