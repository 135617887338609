import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import Feed from "../components/Feed";
import SideBar from "../components/SideBar";
import '../Css/Dashboard.css';
import React, { createRef, useEffect, useRef, useState} from "react";
import { Badge } from "primereact/badge";
import { OverlayPanel } from "primereact/overlaypanel";
import NavPanel from "../components/NavPannel";
import "../Css/NavBar.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Profile from "../components/Profile";
import UserSettings from "../components/UserSettings";
import axios from "axios";
import ProfileOfOther from "../components/ProfileOfOther";
import Page from "../components/Page";
import { Toast } from "primereact/toast";
import { Sidebar } from "primereact/sidebar";
import { InputText } from "primereact/inputtext";
import NewPost from "../components/NewPost";
import { Dialog } from "primereact/dialog";
import NewGruop from "../components/NewGroup";
import PageForm from "../components/PageForm";
import NewPage from "../components/NewPage";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useParams } from 'react-router-dom';
import { Helmet } from "react-helmet";
import SuggestionRow from "../components/SuggestionRow";
import Lottie from 'react-lottie';
import fireworks from '../assests/animation_lk9ixjes.json';
import * as PusherPushNotifications from "@pusher/push-notifications-web";
import { InputSwitch } from "primereact/inputswitch";







function DashboardPage(props)
{

  const navigate = useNavigate();
  const op = React.createRef();
  const toast = createRef();
  const location = useLocation();
  const [fetchedUsers, setFetchedUsers] = useState([]);
  const [fetchedPages, setFetchedPages] = useState([]);
  const [searchData,setSearchData] = useState([]);
  const token = JSON.parse(localStorage.getItem('userToken'));
  const config = {  headers: {"Content-Type": "application/json",Authorization: `Bearer ${token}`} };
  const user = JSON.parse(localStorage.getItem('flickerNetwork') != null) ? JSON.parse(localStorage.getItem('flickerNetwork')) : {username:"" , name:"", avatarPic:"profile-index.png"};
  const [profileOfOther,setProfileOfOther] = useState( ( location.state != null && location.state.profileOfOther != null) ? location.state.profileOfOther : false);
  const [showMessage,setShowMessage] =useState(null);
  const [isPage,setIsPage] = useState( (location.state != null && location.state.isPage != null) ? location.state.isPage : false);
  const [isPublic,setIsPublic] = useState( (location.state != null && location.state.isPublic != null) ? location.state.isPublic : false);
  const [otherUser,setOtherUser]  = useState(profileOfOther && location.state!==null ? location.state.otherUser : null);
  const [page,setPage]  = useState(isPage && location.state!==null ? location.state.page : null);
  const isUserPage  = isPage && location.state!==null ? location.state.isUserPage : null;
  const showBadgeForPage = isPage && location.state!==null ? location.state.showBadge : null;
  const avatarPic = user["avatarPic"];
  const [updatePages, setUpdatePages] = useState(true);
  const [userPages, setUserPages] = useState([]);
  const [userGroups, setUserGroups] = useState([]);
  const [createGroupDialog, setCreateGroupDialog] = useState(false);
  const [CreatePageDialog, setCreatePageDialog] = useState(false);
  const [detailedPageDialog, setDetailedPageDialog] = useState(false);
  const [newPageType,setNewPageType] = useState();
  const [notifCount,setNotifCount] = useState([]);
  const [nonSeenNotifCount,setNonSeenNotifCount] = useState(0); 
  const [mobileSidebar,setMobileSidebar]=useState(false);
  const[showNewPostDialog,setShowNewPostDialog]=useState(false);
  const [desktop, setDesktop] = useState(window.matchMedia("(min-width: 768px)").matches);
  const [uploadProgress,setUploadProgress] = useState(0);
  const [mobileUpdate,setMobileUpdate] = useState(0);
  const tagPage = (props.singlePost===false && user.username==="") ? true : false;
  const { name } = useParams();
  const online = JSON.parse(localStorage.getItem('flickerNetwork')) != null ? true : false;
  const [showAllPages,setShowAllPages] = useState(false);
  const [showAllGroups,setShowAllGroups] = useState(false);
  const [searchValue,setSearchValue] = useState("");
  const [allPosts,setAllPosts] = useState([]);
  const [showSearchbox,setShowSearchbox] = useState(false);
  let hasSearchResult = false;
  const [stickyMenu,setStickyMenu] = useState(false);
  const [showNotifsDialog,setShowNotifsDialog] = useState(false);
  const [showBadge,setShowBadge] = useState(false);
  const [theme,setTheme] = useState(props.theme);
  const [themeSwitch,setThemeSwitch] = useState(props.theme==="light" ? true : false);
  const [searchPostsLoading,setSearchPostsLoading] = useState(false);

  const randomise = (r) =>
  {
         //Fisher-yates shuffle algorithm:
         for(let i=r.length-1; i>0; i--)
         {
            const j = Math.floor(Math.random()*(i+1));
            [r[i],r[j]] = [r[j],r[i]];
         }

         return r;
  }


  useEffect(()=>
  {
    
    if(showMessage===null)
    {
      if(location.state != null && location.state.message != null)
        setShowMessage(location.state.message);
      
    }
    else
      setShowMessage(false);

      

  },[props]);


  const fireworkslottieOptions = {
    loop: 3,
    autoplay: true,
    animationData: fireworks,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
    isClickToPauseDisabled:true 
  
  };
  
  



//update user theme:
const updateTheme = async () =>
{
              const t = !themeSwitch;
              setThemeSwitch(t);
              props.setTheme(t ? "light" : "dark");
              setTheme(t ? "light" : "dark");

              const data = JSON.stringify({theme:t ? "light" : "dark"});
    
                axios.patch(process.env.REACT_APP_BACK+"/settings/theme/"+user._id, data, config)
                .then((response) => {

                if(response.status == 200)
                {
                  console.log(response);
                }
                })
                .catch((error) => {
                  console.log("error",error);
                  if(error.response.data.statusCode===401)
                    navigate("/login")
                });

}


  //enabling notifications && registering service-worker:
  useEffect(() => {

    //register serviceWorker: 
    if ('serviceWorker' in navigator) {


    navigator.serviceWorker.getRegistrations().then(registrations => 
      {

      const isServiceWorkerRegistered = registrations.some(reg => reg.active && reg.active.scriptURL.includes('/service-worker.js'));
      
      if (!isServiceWorkerRegistered) 
      {
        
          navigator.serviceWorker
          .register('/service-worker.js')
          .then(serviceWorkerRegistration => {
            console.log('Service worker registered:', serviceWorkerRegistration);

              //show notification prompt:
          // if (Notification.permission !== 'granted') {

        
            // setShowNotifsDialog(true);
            const beamsClient = new PusherPushNotifications.Client({
              instanceId: '5e51a5ce-509c-4984-9b26-588ae00e2bb5',
              serviceWorkerRegistration: serviceWorkerRegistration,
            });
          
            //use username as intrest and send notifications to this intrest:
            beamsClient.start()
              .then(() => beamsClient.addDeviceInterest(user._id))
              .then(() => beamsClient.getDeviceInterests())
              .then((interests) => console.log("Current interests:", interests))
              .catch(console.error);
          // }




          })
        
          .catch(error => {
            console.log('Service worker registration failed:', error);
          });
        }
        
      })
      .catch(error => {
        console.log('Error checking service worker registrations:', error);
      });
      
    }
    

  

  }, []);



  //***************************************  top search box   ******************************************************************/
   const handleSearch = (event) => {
    let { value } = event.target;
    
    setSearchValue(value);
    setShowSearchbox(true);
    if(value==="")
    {
      setAllPosts([]);
    }
  };

  // Create a debounced version of the API request
  useEffect(() => {
    
    const delayedApiRequest = setTimeout(() => {
      if (searchValue) {
        fetchPosts(searchValue.replace(/\s+/g,"_"));
      }
    }, 500);

    
    return () => clearTimeout(delayedApiRequest);
  }, [searchValue]);


  async function fetchPosts(searchValue) {

    let heightIndex = 1;
    let offset = 10;


    await setAllPosts([]);
    await setSearchPostsLoading(true);
    
    await axios.get(process.env.REACT_APP_BACK+"/posts/search/"+searchValue+"/"+offset,config)

      .then((result) => {

        if (result.status == 200) {
          setAllPosts(result.data);
          setSearchPostsLoading(false);
          console.log("fetched searched posts: ", result.data);
          return result.data;
        }
      })
      .catch((error) => {
        console.log(error);
        if(error.response.data.statusCode===401)
               navigate("/login")
      });



      const dialogElement = document.querySelector('#searchbox');
      if (dialogElement) {
       

        dialogElement.addEventListener('scroll', async function() {

         
      
        let scrollPosition = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;  
        let visibleHeight = document.documentElement.clientHeight;    // Get the visible height of the screen
        let distanceScrolled = scrollPosition + visibleHeight;        // Calculate the distance the user has scrolled from the top of the page
  
  
        if (distanceScrolled >= (66.6*heightIndex) ) {                 // Check if the user has scrolled down a certain amount
            
          
          heightIndex += 1;
            
      //fetch next:
      if(heightIndex === (offset))
      {
        offset += 10;
        await setSearchPostsLoading(true);
  
        await axios
        .get(process.env.REACT_APP_BACK+"/posts/search/"+searchValue+"/"+offset, config)
        .then((result) => {
          if (result.status == 200) {
  
            setAllPosts(prevArray => [...prevArray, ...randomise(result.data)]); 
            console.log("fetched searched posts: ", result.data);
            
            
            return result.data;
          }
        })
        .catch((error) => {
          console.log(error);
          if(error.response.data.statusCode===401)
                navigate("/login")
        });
          
      
  
        setSearchPostsLoading(false);
        
        
  
      }
  
     
      }
    });
      }

}
/*********************************************************************************************************************** */
 
  
  
  
  //go to top of the page:
  useEffect(() => {

    window.scrollTo(0, 0)
  }, []);
  
   
  //in case of page or otheruser has not been set:  
  useEffect(() => {


    if(location.state === null && user.username!==name)
    {
      if(props.panelType==="profile")
      {

      const result = axios.get(process.env.REACT_APP_BACK+"/pages/user-or-page/"+name, config)
      .then((result) => {
        if (result.status == 200) {
          
          console.log("is page or other user:",result.data)

          //page:
          if(result.data.isPage)
          {
            setPage(result.data.result);
            setIsPage(true);
          }
          //other user:
          else
          {
            setProfileOfOther(true);
            setOtherUser(result.data.result);
          }
          
          return result.data;
        }
      })
      .catch((error) => {
        console.log(error);
        if(error.response.data.statusCode===401)
               navigate("/login")
      });

    }
    else
    {
      setProfileOfOther(false);
    }

    }
    else
    {
      if(location.state !== null)
      {
        if(location.state.profileOfOther!==null)
          setProfileOfOther(location.state.profileOfOther);
        if(location.state.isPage!==null)
          setIsPage(location.state.isPage);
        if(location.state.otherUser!==null)
          setOtherUser(location.state.otherUser);
      }
    }

    
    
    
  }, [props]);


  //check desktop or mobile:
  useEffect(() => {

    
    window
    .matchMedia("(min-width: 768px)")
    .addEventListener('change', e => setDesktop( e.matches ));



  }, []);

  const closeNewPost = (uploadFile,fileType,values,type) => {
    submitPost(uploadFile,fileType,values,type);
    setShowNewPostDialog(false);
    
    
  };


  //submit new post:
  const submitPost = (uploadFile,fileType,values,type) =>
  {
    //uploading file
    let formData = new FormData();
    formData.append("file", uploadFile);

    const c = {
      headers: {
        "Contetnt-Type": "multipart/form-data",
      },
    };
    
    axios
      .post(process.env.REACT_APP_BACK+"/posts/uploadFile", formData, { c
      ,
      onUploadProgress: (progressEvent) => {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;
        setUploadProgress(progress);
      },
      onDownloadProgress: (progressEvent) => {
    
      },
      })
      .then((res) => {
        //Now do what you want with the response;

        

        let fileName = res.data.fileName;

        let creator;
        if(isPage)
          creator=page;
        else
          creator=user;

          

        var data = {
          
          description: values.description,
          category: values.category,
          file:fileName,
          creatorAvatar:creator.avatarPic,
          creatorJob:creator.job,
          creatorName:creator.name,
          creatorUsername:creator.username,
          type:type,
          accountType:creator.accountType,
          fileType:fileType,
          tags:values.tags,
          taggedUsers:values.taggedUsers
        };

        if(isPage)
          data["creatorPage"] = creator._id;
        else
          data["creator"] = creator._id;

        data = JSON.stringify(data);

        //creating post in database
   

        axios
          .post(process.env.REACT_APP_BACK+"/posts", data, config)
          .then((response) => {
            console.log("response",response);


            if(!isPage && response.data.roset1)
                  setShowBadge(true);


            setMobileUpdate(mobileUpdate+1);


            //navigate:
            if(type === "normal")
              navigate("/feed");
            else if(type==="product")
            {
              if(isPage)
              navigate("/" + page["username"] + "/posts",
              { state: {page: page, isPage: true , isUserPage:isUserPage }});
              else
              {
                if(values.category==="لوازم اسب")
                  navigate("/shop/horse");
                else if(values.category==="لوازم سوار")
                  navigate("/shop/rider");
              }
            }

            
            
          })
          .catch((error) => {
            console.log("error",error);
            if(error.response.data.statusCode===401)
               navigate("/login")
          });
      })
      .catch((error) =>
      {
        if(error.response.data.statusCode===401)
               navigate("/login")
      });

  }
 

  //if user cache is lost, go to home page(ex: tagPage):
    useEffect(() => 
    {
      
        if(JSON.parse(localStorage.getItem('flickerNetwork')) === null && !tagPage && !isPage && !isPublic)
            navigate("/login");

    });

  //get unseen notifications count:
  useEffect(() => {
    async function notifCount() {

      
      const result = await axios.get(process.env.REACT_APP_BACK+"/notifications/count/"+user._id, config)
      .then((result) => {
        if (result.status == 200) {
          setNotifCount(result.data);
          console.log("none seen notifs:",result.data);
          return result.data;
        }
      })
      .catch((error) => {
        console.log(error);
        if(error.response.data.statusCode===401)
               navigate("/login")
      });

      
      return result.data;

    }

    async function nonSeenNotifCount()
    {
      const result = await axios.get(process.env.REACT_APP_BACK+"/notifications/nonSeenNotifCount/"+user._id, config)
      .then((result) => {
        if (result.status == 200) {
          setNonSeenNotifCount(result.data);
          console.log("none seen notifs count:",result.data);
          return result.data;
        }
      })
      .catch((error) => {
        console.log(error);
        if(error.response.data.statusCode===401)
               navigate("/login")
      });

      
      return result.data;

    }
    
    notifCount();
    nonSeenNotifCount();
  },[]);



  useEffect(() => {
    async function fetchUsers() {
      
      axios
        .get(process.env.REACT_APP_BACK+"/users/suggest/" + 3, config)
        .then((result) => {
          if (result.status == 200) {
            setFetchedUsers(result.data);
            console.log("fetched suggested users: ", result.data);
            return result.data;
          }
        })
        .catch((error) => {
          console.log(error);
          if(error.response.data.statusCode===401)
               navigate("/login")
        });
    
      axios
        .get(process.env.REACT_APP_BACK+"/pages/suggest/" + 3, config)
        .then((result) => {
          if (result.status == 200) {
            setFetchedPages(result.data);
            console.log("fetched suggested pages: ", result.data);
            return result.data;
          }
        })
        .catch((error) => {
          console.log(error);
          if(error.response.data.statusCode===401)
               navigate("/login")
        });
    
    }

  if(desktop)
    fetchUsers();

  
  },[]);

  const logout = () => 
  {
    console.log("atemped to log out");


    axios.get(process.env.REACT_APP_BACK+"/users/logout", config)
    .then((response) => {
      if (response.status == 200)
        navigate("/");
      console.log(response);
    })
    .catch((error) => {
      console.log(error);
      if(error.response.data.statusCode===401)
               navigate("/login")
    });

    //delete user cache:
    localStorage.removeItem('flickerNetwork');
    localStorage.removeItem('userToken');

    //make theme light:
    props.setTheme("light");

  
  }




  const deletePage = () => 
  {
    toast.current.show({
      severity: "success",

      detail: "صفحه با موفقیت حذف شد",
    });
  }

  const closePageForm = () => {
    setDetailedPageDialog(false);
    setUpdatePages(true);
  };
  const closeGroupForm = () => {
    setCreateGroupDialog(false);
    setUpdatePages(true);
  };

  const openDetailedPage = (type) => {
    setNewPageType(type);
    setCreatePageDialog(false);
    setDetailedPageDialog(true);
  };


//fetching user pages(mobile):
useEffect(() => {
  async function fetchUserPages() {
    await axios
      .get(process.env.REACT_APP_BACK+"/pages/creator/" + user._id, config)
      .then((result) => {
        if (result.status == 200) {
          setUserPages(result.data.filter(item => item.type !== "group"));
          console.log("fetched user pages: ", result.data);
          return result.data;
        }
      })
      .catch((error) => {
        console.log(error);
        if(error.response.data.statusCode===401)
               navigate("/login")
      });
  }

  async function fetchUserGroups() {
    await axios
      .get(process.env.REACT_APP_BACK+"/notifications/userGroups/" + user._id, config)
      .then((result) => {
        if (result.status == 200) {
          setUserGroups(result.data);
          console.log("fetched user groups: ", result.data);
          return result.data;
        }
      })
      .catch((error) => {
        console.log(error);
        if(error.response.data.statusCode===401)
               navigate("/login")
      });
  }

  if (!desktop && updatePages && mobileSidebar ) {
    fetchUserPages();
    fetchUserGroups();
    setUpdatePages(false);
  }
}, [props, updatePages , mobileSidebar]);

  
  return(
    
    <div id='dashboard'>
      



  <Helmet>
     <meta charSet="utf-8"/>
      <title>داشبورد</title>
      <meta name="description" content="اطلاعات خود از دنیای اسب و سوارکاری را با دیگران به اشتراک بگذارید"/>
  </Helmet>

  
    {/* badge animation */}
    <div onClick={()=> setShowBadge(false)}>
    {showBadge && 
    <div style={{position:"absolute" , height:"100vh",zIndex:"1000"}} >
    <Lottie     options={fireworkslottieOptions}  />
    
      <div className="popup">
        <div className="popup-content">
          <img src={require("../images/rosette.png")} alt="فلیکا ، شبکه اجتماعی سوارکاری" className="zoom-in" style={{width:"50%",height:"auto"}}/>
        </div>
      </div>
    </div>
    
    }
    </div>


      <Dialog
        className={theme+" install-app"}
        position="center"
        header="فعال کردن اعلان ها"
        visible={showNotifsDialog}
        onHide={() => setShowNotifsDialog(false)}
      >
        <img className="notif-prompt" src={require("../images/notifsPrompt.png")} />


        <p>
        برای بهبود تجربه و بروزرسانی با پلتفرم ما، هنگام پرسش برای فعال سازی اعلان‌ها، به سادگی بر روی 'alow' کلیک کنید. 

        </p>

        <Button onClick={()=>setShowNotifsDialog(false)}>باشه</Button>
          
      </Dialog>

 {/* mobile top menu **************************************************************************************/}
      
      {!desktop && online ? 
      <div>
      <div id="mobile-nav1" className={stickyMenu ? "sticky-menu active" : "sticky-menu" }>
        <span style={{display:"flex", direction:"rtl"}}>
        <span style={{display:"flex",width:"20%"}}>
          <div id="profile-icon">
          <a>
          <img src={`${process.env.REACT_APP_BACK}/avatars/${avatarPic}`}
                alt={`فلیکا ، شبکه اجتماعی سوارکاری - ${user.name}`}  
              onClick={(e) => {setMobileSidebar(true)}}
            />
          </a>
          </div>
        </span>
        <span style={{display:"flex",width:"60%"}}>
        <span className="p-input-icon-right search-box-wraper">
            <i className="pi pi-search" />
            <InputText
              className="search-box"
              placeholder="اینجا جستجو کنید ..."
              value={searchValue}
              onChange={handleSearch}
            />
          </span>
        </span>
        <span style={{display:"flex",width:"20%",direction:"ltr"}}>
        <div id="logo-icon">
          <Link to="/feed">
              <img src={require("../images/logo.png")} />
          </Link>
      </div>
        </span>

        </span>


        
      </div>

      {/*show search results: */}

      <div id="searchbox" style={{display: searchValue!=="" ?  "block" : "none", marginTop: showSearchbox ? "-20px" : "0px"}}>

      
         {/* load animation */}
         {searchPostsLoading ? 
            <div className="spinner" style={{marginBottom:"50px"}}></div>
        : null}


      {allPosts.length>0 && showSearchbox && (
      
      <ul>

        
              {allPosts
                .map((post, index) => {
                  return (
                    <div className={"user" + index} key={index}>
                      <div id="suggestion-box" className="search-row">
                        <SuggestionRow
                          key={index}
                          username={post.creator!==undefined ? post.creator.username : post.creatorPage.username}
                          name={post.creator!==undefined ? post.creator.name : post.creatorPage.name}
                          job={post.creator!==undefined  ? post.creator.job : post.creatorPage.job }
                          image={post.file}
                          fileType={post.fileType}
                          description={post.description}
                          _id={post._id}
                          isPost={true}
                          type="searchbox"
                        ></SuggestionRow>
                      </div>
                    </div>

                    
                  );
                  
                })}

                

                
    
      </ul>


      )}

      {allPosts.length===0 && showSearchbox ?<span style={{marginTop:"20px"}}>  نتیجه ای یافت نشد </span>: null}

        </div>


      <div id="upload-progress" style={{display: (uploadProgress===100 || uploadProgress===0) ? "none" : "block"}}>
          
          <span>
          <div className="row1"><img src={process.env.REACT_APP_BACK+`/avatars/${avatarPic}`}></img></div>
          <div className="row2"><LinearProgress variant="determinate" value={uploadProgress} /></div>
          </span>
          
        </div>


      </div>

      
        : null}
{/* ********************************************************************************************************* */}


      <Toast ref={toast} life="3000" position="center center" />
      {props.panelType != "profile" && props.panelType != "settings" && online ? <div id="sidebar-wrap"><SideBar theme={theme} fetchedUsers={fetchedUsers} fetchedPages={fetchedPages}></SideBar></div> : null}
      {props.panelType === "feed" || props.panelType === "search" || props.panelType=="notification" || props.panelType=="shop"  ? <div id="feed-wrap"><Feed theme={theme} mobileUpdate={mobileUpdate} type={props.panelType} searchType={props.searchType} shopTab={props.shopTab} searchData={searchData} singlePost={props.singlePost} tagPage={tagPage}></Feed></div> : null}
      {(props.panelType === "profile" && !profileOfOther && !isPage) ? <Profile profileTab={props.profileTab} user={user} profileOfOther={profileOfOther} /> : null}
      {(props.panelType === "profile" && profileOfOther && !isPage) ? <ProfileOfOther profileTab={props.profileTab} user={otherUser} profileOfOther={profileOfOther} message={location.state.message} setShowMessage={setShowMessage} /> : null}
      {(props.panelType === "profile" && isPage) ? <Page theme={theme} page={page} isUserPage={isUserPage} profileTab={props.profileTab} deletePage={deletePage} showBadge={showBadgeForPage}/> : null}
      {props.panelType === "settings" ? <UserSettings theme={theme} settingsTab={props.settingsTab} fetchSettings={props.fetchSettings}/> : null}
      {props.panelType === "mobile-notification" ? <NavPanel theme={theme} type="notification" notifCount={notifCount} ></NavPanel> : null}
      {props.panelType === "new-post" ? <NewPost  progress={uploadProgress} theme={theme} type="normal"  close={closeNewPost}></NewPost> : null}
      {props.panelType === "new-product" ? <NewPost  progress={uploadProgress} theme={theme} type="product"  close={closeNewPost}></NewPost> : null}
      

{desktop && JSON.parse(localStorage.getItem('flickerNetwork')) != null ?
  
    <div id="navigation" 
    style={{
      width: !profileOfOther && !isPage ? "28%" : "6%"
      
  }}
  >
    
      <div id="no-scrol">
    <div id="nav-bar-and-pannel">

    

    {/* we donnot have navpanel for profile of others, pages, and tags */}
    { !profileOfOther && !isPage && !tagPage ? <div id="nav-bar-panel"><NavPanel type={props.panelType} notifCount={notifCount} ></NavPanel></div> : null }

    <div id="nav-bar-wrap">
      <div id="logo-icon">
      <Link to="/feed">
          <img src={require("../images/logo.png")} />
      </Link>
      </div>

      <div id="nav-icons"
        
      >
        <div className="nav-bar-item">

          <Link to="/feed">
          <Button>
            <i className="pi pi-home"></i>
          </Button>
          </Link>
        </div>

        <div className="nav-bar-item">
        <Link to="/search">
          <Button id="search-button">
            <i className="pi pi-search"></i>
          </Button>
          </Link>
        </div>

        <div className="nav-bar-item">
       
        <Link to="/notification" state={{ notifCount }}>
          <Button>

        {/* show notifications count     */}
        { nonSeenNotifCount != undefined && nonSeenNotifCount != 0 ? 
            <Badge value={nonSeenNotifCount} icon="pi pi-bell" severity="danger" />
        : null }
            <i className="pi pi-bell"></i>
          </Button>
          </Link>
        </div>

        <div className="nav-bar-item">

        <Link to="/shop/rider">
          <Button>
            <i className="pi pi-shopping-cart"></i>
          </Button>
          </Link>
        </div>
      </div>

      <div id="profile-icon">
        <a>
        <img src={`${process.env.REACT_APP_BACK}/avatars/${avatarPic}`} alt={`فلیکا ، شبکه اجتماعی سوارکاری - ${user.name}`}  
            onClick={(e) => {op.current.toggle(e)}}
          />
        </a>

        
      </div>
    </div>



    </div>
    </div>

          
</div>

: null

}


{/* mobile nav ********************************************************************************/}
{!desktop && online ? 

<div style={{zIndex:"1000",position: "fixed",bottom:"0",direction:"rtl",width:"100%",background:"#ffffff",boxShadow: "rgba(0, 0, 0, 0.81) 0px 1px 4px"}}>

<span style={{display: !showMessage ?"flex" : "none"}} id="nav-icons">

<span style={{display:"flex",direction:"rtl",width:"82%"}}>
  <div className="nav-bar-item">
    <Link to="/feed">
    <Button>
      <i className="pi pi-home"></i>
    </Button>
    </Link>
  </div>

  <div className="nav-bar-item">
  <Link to="/search">
    <Button id="search-button">
      <i className="pi pi-search"></i>
    </Button>
    </Link>
  </div>

  <div className="nav-bar-item">
 
  <Link to="/notifs" state={{ notifCount }}>
    <Button>

  {/* show notifications count     */}
  { nonSeenNotifCount != undefined && nonSeenNotifCount != 0 ? 
      <Badge value={nonSeenNotifCount} severity="danger" />
  : null }
      <i className="pi pi-bell"></i>
    </Button>
    </Link>
  </div>

  <div className="nav-bar-item">

  <Link to="/shop/rider">
    <Button>
      <i className="pi pi-shopping-cart"></i>
    </Button>
    </Link>
  </div>
  </span>

  <span className="nav-bar-item" style={{display:"flex",direction:"ltr",width:"18%"}}>

  
    <Button 
    onClick={()=>
        navigate("/new-post")
      // setShowNewPostDialog(true);
    }
     style={{padding: "0px",marginLeft: "10px"}}>
    <i className="pi pi-plus" style={{color:"#af734f"}}></i>
    </Button>
    
  </span>

</span>
</div>
: null}

{/********************************************************************************************/}


<OverlayPanel
          className={theme}
          breakpoints={{ "960px": "75vw", "640px": "100vw" }}
          style={{ width: "300px" }}
          ref={op}
        >
          <a onClick={(e) => {op.current.hide(e); navigate("/"+user["username"]+"/about",{state:{user: user , profileOfOther:false}})}} >
          <div className="profile-panel">
            <div className="col-one">
            <i className="pi pi-eye" style={{verticalAlign:"middle", paddingLeft:"10px"}}></i>
            </div>
            <div className="col-two">
              {user["name"]}
              <br/>
              مشاهده پروفایل
            </div>
            <div className="col-three">
            <img src={`${process.env.REACT_APP_BACK}/avatars/${avatarPic}`}></img>
            </div>
          </div>
          </a>

         {/* theme setting */}
         <div id="theme-setting">
          <span className="settings-row" style={{ display:"flex",alignItems:"center"}}>
          {/* <h6 style={{ display:"flex", marginLeft:"20px" , fontSize:"16px"}}>تم: </h6> */}
                  <i className="pi pi-sun" style={{ display:"flex", marginLeft:"10px",fontSize:"20px"}}></i>
                  <InputSwitch
                    checked={themeSwitch}
                    onChange={(e) => {
                      updateTheme();
                      
                    }}
                    style={{ display:"flex"}}
                  />
                  <i className="pi pi-moon" style={{ display:"flex", marginRight:"10px"}}></i>
                </span>
              

          </div>



          { user.role === "manager" ? 
          <div style={{marginTop:"10px",display:"table",margin:"auto"}}>
              <Button onClick = {()=>navigate("/manager-of-flicka")}>
              پنل مدیریت
              </Button>
            </div>

            : null }


          <div><Divider/></div>

          
          <div className="profile-panel" onClick={(e) => {op.current.hide(e); navigate("/settings/account");}}>
            <div className="col-one">
            
            </div>
            <div className="col-two">
              تنظیمات
            </div>
            <div className="col-three">
            <i className="pi pi-cog"></i>
            </div>
            
            
          </div>
          

          <div className="profile-panel">
            <div className="col-one">
            
            </div>
            <div className="col-two">
              راهنما
            </div>
            <div className="col-three">
            <i className="pi pi-question"></i>
            </div>
            
            
          </div>

          <div className="profile-panel">
            <div className="col-one">
            
            </div>
            <div className="col-two">
              ارسال نظرات و پیشنهادات
            </div>
            <div className="col-three">
            <i className="pi pi-envelope"></i>
            </div>
            
            
          </div>

          <div><Divider/></div>

          <div className="profile-panel">
            <div className="col-one">
            
            </div>
            <div className="col-two" onClick={logout}>
              خروج
            </div>
            <div className="col-three">
            <i className="pi pi-sign-out"></i>
            </div>
            
            
          </div>

        </OverlayPanel>

{/* mobile sidebar **************************************************************************************/}
        <Sidebar id={"mobile-sidebar-"+theme} position="right" visible={mobileSidebar} style={{width:'70%'}} onHide={() => setMobileSidebar(false)}>

        <a onClick={(e) => {navigate("/"+user["username"]+"/about"); setMobileSidebar(false);}}>
          <div className="profile-panel-header">
            <div className="col-three">
            <img src={`${process.env.REACT_APP_BACK}/avatars/${avatarPic}`}></img>
            </div>
            <div className="col-one">
            {user["name"]}
            </div>
            <div className="col-two">
              مشاهده پروفایل
            </div>
            
          </div>
          </a>

          {/* theme setting */}
          <div id="theme-setting">
          <span className="settings-row" style={{ display:"flex",alignItems:"center"}}>
          {/* <h6 style={{ display:"flex", marginLeft:"20px" , fontSize:"16px"}}>تم: </h6> */}
                  <i className="pi pi-sun" style={{ display:"flex", marginLeft:"10px",fontSize:"20px"}}></i>
                  <InputSwitch
                    checked={themeSwitch}
                    onChange={(e) => {
                      updateTheme();
                      
                    }}
                    style={{ display:"flex"}}
                  />
                  <i className="pi pi-moon" style={{ display:"flex", marginRight:"10px"}}></i>
                </span>
              

          </div>

          { user.role === "manager" ? 
          <div className="profile-panel-header" style={{marginTop:"10px"}}>
              <Button onClick = {()=>navigate("/manager-of-flicka")}>
              پنل مدیریت
              </Button>
            </div>

            : null }

          <div><Divider/></div>


          <div>
          <p style={{fontSize:"12px", textAlign:"right",color:"#000000"}}>صفحه های من</p>

          

          {showAllPages ?  
          <div>
            {userPages.map((page, index) =>
            page.type != "group" ? (
              <div
                className="panel-row"
                key={index}
                onClick={() =>
                  navigate("/" + page.username + "/about", {
                    state: { page: page, isPage: true, isUserPage: true },
                  })
                }
              >
                <span className="col-two">{page.name}</span>
                <span className="col-one">
                  <img
                    src={
                      process.env.REACT_APP_BACK + `/avatars/${page.avatarPic}`
                    }
                  ></img>
                </span>
              </div>
            ) : null
          )}
          </div> : 
          
          <div>
            {userPages.slice(0, 4).map((page, index) =>
            
              <div
                className="panel-row"
                key={index}
                onClick={() =>
                  navigate("/" + page.username + "/about", {
                    state: { page: page, isPage: true, isUserPage: true },
                  })
                }
              >
                <span className="col-two">{page.name}</span>
                <span className="col-one">
                  <img
                    src={
                      process.env.REACT_APP_BACK + `/avatars/${page.avatarPic}`
                    }
                  ></img>
                </span>
              </div>
            
          )}
            </div>          
          }

          {userPages.length>4 && !showAllPages && <div style={{display: "table", margin: "auto"}}> <Button className="view-more-button" onClick={()=> setShowAllPages(!showAllPages)}>بیشتر ...</Button></div>}
          {userPages.length>4 && showAllPages && <div style={{display: "table", margin: "auto"}}> <Button  className="view-more-button" onClick={()=> setShowAllPages(!showAllPages)}> کمتر ...</Button></div>}


          <div className="panel-row" onClick={() => setCreatePageDialog(true)}>
            <span className="col-two" style={{ color: "#af734f" }}>
              ایجاد صفحه جدید
            </span>
            <span className="col-one">
              <i className="pi pi-plus-circle" style={{ color: "#af734f" }}></i>
            </span>
          </div>

          <p style={{fontSize:"12px", textAlign:"right",color:"#000000"}}>گروه های من</p>

          {showAllGroups ?  
          <div>
            {userGroups.map((page, index) =>
            
              <div
                className="panel-row"
                key={index}
                onClick={() =>
                  navigate("/" + page.username + "/about", {
                    state: { page: page, isPage: true, isUserPage: true },
                  })
                }
              >
                <span className="col-two">{page.name}</span>
                <span className="col-one">
                  <img
                    src={
                      process.env.REACT_APP_BACK + `/avatars/${page.avatarPic}`
                    }
                  ></img>
                </span>
              </div>
            
          )}
          </div> : 
          
          <div>
            {userGroups.slice(0, 4).map((page, index) =>
            
              <div
                className="panel-row"
                key={index}
                onClick={() =>
                  navigate("/" + page.username + "/about", {
                    state: { page: page, isPage: true, isUserPage: true },
                  })
                }
              >
                <span className="col-two">{page.name}</span>
                <span className="col-one">
                  <img
                    src={
                      process.env.REACT_APP_BACK + `/avatars/${page.avatarPic}`
                    }
                  ></img>
                </span>
              </div>
            
          )}
            </div>          
          }

          {userGroups.length>4 && !showAllGroups && <div style={{display: "table", margin: "auto"}}> <Button className="view-more-button" onClick={()=> setShowAllGroups(!showAllGroups)}>بیشتر ...</Button></div>}
          {userGroups.length>4 && showAllGroups && <div style={{display: "table", margin: "auto"}}> <Button  className="view-more-button" onClick={()=> setShowAllGroups(!showAllGroups)}> کمتر ...</Button></div>}


          <div className="panel-row" onClick={() => setCreateGroupDialog(true)}>
            <div className="col-two" style={{ color: "#af734f" }}>
              ایجاد گروه جدید
            </div>
            <div className="col-one">
              <i className="pi pi-plus-circle" style={{ color: "#af734f" }}></i>
            </div>
          </div>

         
          <div><Divider/></div>

          <div
            className="panel-row2"
            onClick={() => {navigate("/notification", { state: { user: user } }); setMobileSidebar(false);}}
          >
            <div className="col-two">پست های ذخیره شده</div>
            <div className="col-one">
              <i className="pi pi-bookmark"></i>
            </div>
          </div>
          </div>

          <div
            className="panel-row2"
            onClick={(e) => {navigate("/settings/account"); setMobileSidebar(false); }}
          >
            <div className="col-two">تنظیمات</div>
            <div className="col-one">
              <i className="pi pi-cog"></i>
            </div>
          </div>

          <div
            className="panel-row2"
            onClick={(e) => {logout(e)}}
          >
            <div className="col-two">خروج</div>
            <div className="col-one">
              <i className="pi pi-sign-out"></i>
            </div>
          </div>

       
        </Sidebar>

{/*******************************************************************************************************/}
        
            <Dialog
              className={theme + " new-post-dialog"}
              breakpoints={{ "960px": "75vw", "640px": "100vw" }}
              position="top"
              header="پست جدید"
              visible={showNewPostDialog}
              style={{ width: "40vw" }}
              onHide={() => setShowNewPostDialog(false)}
        
            >
              <NewPost type="normal" user={user} close={closeNewPost} theme={theme}/>
            </Dialog>

        <Dialog
        id="create-page-dialog"
        header="ایجاد صفحه جدید"
        visible={CreatePageDialog}
        className={theme }
        onHide={() => setCreatePageDialog(false)}
      >
        <NewPage close={openDetailedPage} />
      </Dialog>

      <Dialog
        className={theme + " detailed-page-dialog"}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        header="صفحه جدید"
        visible={detailedPageDialog}
        style={{ width: "40vw" }}
        onHide={() => setDetailedPageDialog(false)}
      >
        <PageForm  setShowBadge={setShowBadge} type={newPageType} user_id={user._id} creatorName={user.name} close={closePageForm}></PageForm>
      </Dialog>

      <Dialog
        id="create-group-dialog"
        header="ایجاد گروه جدید"
        visible={createGroupDialog}
        className={theme}
        onHide={() => setCreateGroupDialog(false)}
      >
        <NewGruop type={"group"} user_id={user._id} creatorName={user.name} close={closeGroupForm} />
      </Dialog>
        
        </div>
  );

}
export default DashboardPage;
