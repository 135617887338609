import { useEffect, useState } from "react";
import "../Css/Weblog.css";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import SuggestionRow from "./SuggestionRow";
import Tag from "./Tag";


function WeblogSidebar1(props)
{

    const [fetchedHorsePrs,setfetchedHorsePrs] = useState([]);
    const [fetchedRiderPrs,setfetchedRiderPrs] = useState([]);
    const token = JSON.parse(localStorage.getItem('userToken'));
    const config = {  headers: {"Content-Type": "application/json",Authorization: `Bearer ${token}`} };
    const [searchValue,setSearchValue] = useState("");
    const [showSearchbox,setShowSearchbox] = useState(false);
    const [allproducts,setAllProducts] = useState([]);
    const [searchProductsLoading,setSearchProductsLoading] = useState(false);
    const [fetchedtags,setFetchedTags] = useState([]);
    const [desktop, setDesktop] = useState(window.matchMedia("(min-width: 768px)").matches);
    const navigate = useNavigate();


    // Group products into pairs:
    const horsePrsRows = [];
    for (let i = 0; i < fetchedHorsePrs.length; i += 2) {
        horsePrsRows.push(fetchedHorsePrs.slice(i, i + 2));
    }

    const riderPrsRows = [];
    for (let i = 0; i < fetchedRiderPrs.length; i += 2) {
        riderPrsRows.push(fetchedRiderPrs.slice(i, i + 2));
    }



    // fetch related products:
    useEffect(() => {


        async function fetchHorsePrs() {

     
          await axios.get(process.env.REACT_APP_BACK+"/articles/horse_prs/"+props.article_id, config)
            .then((result) => {
              if (result.status == 200) {
                setfetchedHorsePrs(result.data);
                console.log("horse products",result.data);
                return result.data;
              }
            })
            .catch((error) => {
              console.log(error);
              if(error.response.data.statusCode===401)
                 navigate("/login")
            });
  
        }


        async function fetchRiderPrs() {

     
            await axios.get(process.env.REACT_APP_BACK+"/articles/rider_prs/"+props.article_id, config)
              .then((result) => {
                if (result.status == 200) {
                  setfetchedRiderPrs(result.data);
                  console.log("rider products",result.data);
                  return result.data;
                }
              })
              .catch((error) => {
                console.log(error);
                if(error.response.data.statusCode===401)
                   navigate("/login")
              });
    
          }


          async function fetchRandomPrs() {

     
            //horse prs:
            await axios.get(process.env.REACT_APP_BACK+"/posts/products/horse/"+8, config)
              .then((result) => {
                if (result.status == 200) {
                  setfetchedHorsePrs(result.data);
                  console.log("horse products",result.data);
                  return result.data;
                }
              })
              .catch((error) => {
                console.log(error);
                if(error.response.data.statusCode===401)
                   navigate("/login")
              });

              
              //rider prs:
              await axios.get(process.env.REACT_APP_BACK+"/posts/products/rider/"+8, config)
              .then((result) => {
                if (result.status == 200) {
                  setfetchedRiderPrs(result.data);
                  console.log("rider products",result.data);
                  return result.data;
                }
              })
              .catch((error) => {
                console.log(error);
                if(error.response.data.statusCode===401)
                   navigate("/login")
              });
    
          }

       

        //single article:
        if(props.singleArticle)
        {
            fetchHorsePrs();
            fetchRiderPrs();
            
        }
        else
            fetchRandomPrs();

        
        
     
      
    }, []);

    
    
    
    //fetching popular tags:
    useEffect(() => {
      
        async function fetchTags() {
                       
            axios
            .get(process.env.REACT_APP_BACK+"/posts/popular_tags/", config)
            .then((result) => {
                if (result.status == 200) {
                setFetchedTags(result.data);
                console.log("fetched Popular Tags: ", result.data);
                return result.data;
                }
            })
            .catch((error) => {
                console.log(error);
                if(error.response.data.statusCode===401)
                    navigate("/login")
            });
        
        }

    fetchTags();

  }, []);



    //search*************************************************************************************************************************8
    const handleSearch = (event) => {
      let { value } = event.target;
      
      setSearchValue(value);
      setShowSearchbox(true);
      if(value==="")
      {
        setAllProducts([]);
      }
    };
    // Create a debounced version of the API request
      useEffect(() => {
      
          const delayedApiRequest = setTimeout(() => {
          if (searchValue) {
              fetchForSearch(searchValue.replace(/\s+/g,"_"));
          }
          }, 500);
      
          
          return () => clearTimeout(delayedApiRequest);
      }, [searchValue]);



      async function fetchForSearch(searchValue) {

          let heightIndex = 1;
          let offset = 10;
        
        
          await setAllProducts([]);
          await setSearchProductsLoading(true);
          
          await axios.get(process.env.REACT_APP_BACK+"/posts/search-all-products/"+searchValue+"/"+offset,config)
        
            .then((result) => {
        
              if (result.status == 200) {
                setAllProducts(result.data);
                setSearchProductsLoading(false);
                console.log("fetched searched products: ", result.data);
                return result.data;
              }
            })
            .catch((error) => {
              console.log(error);
              if(error.response.data.statusCode===401)
                    navigate("/login")
            });
        
        
        
            const dialogElement = document.querySelector('#searchbox');
            if (dialogElement) {
            
        
              dialogElement.addEventListener('scroll', async function() {
        
              
            
              let scrollPosition = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;  
              let visibleHeight = document.documentElement.clientHeight;    // Get the visible height of the screen
              let distanceScrolled = scrollPosition + visibleHeight;        // Calculate the distance the user has scrolled from the top of the page
        
        
              if (distanceScrolled >= (66.6*heightIndex) ) {                 // Check if the user has scrolled down a certain amount
                  
                
                heightIndex += 1;
                  
            //fetch next:
            if(heightIndex === (offset))
            {
              offset += 10;
              await setSearchProductsLoading(true);
        
              await axios
              .get(process.env.REACT_APP_BACK+"/posts/search-all-products/"+searchValue+"/"+offset, config)
              .then((result) => {
                if (result.status == 200) {
        
                  setAllProducts(prevArray => [...prevArray, ...randomise(result.data)]); 
                  console.log("fetched searched products: ", result.data);
                  
                  
                  return result.data;
                }
              })
              .catch((error) => {
                console.log(error);
                if(error.response.data.statusCode===401)
                      navigate("/login")
              });
                
            
        
              setSearchProductsLoading(false);
              
              
        
            }
        
          
            }
          });
            }
        
        }

        const randomise = (r) =>
          {
                //Fisher-yates shuffle algorithm:
                for(let i=r.length-1; i>0; i--)
                {
                    const j = Math.floor(Math.random()*(i+1));
                    [r[i],r[j]] = [r[j],r[i]];
                }
        
                return r;
          }


    return(
        <div id="weblogsidebar1" >

            <div className="no-scrol sidebar_container">

                <h4 style={{textAlign:"right"}}>فروشگاه فلیکا</h4>


                <div id="searchSection" style={{marginBottom:"20px"}}>

            <span className="p-input-icon-right search-box-wraper">
            <i className="pi pi-search" />
            <InputText
              className="search-box"
              placeholder="اینجا جستجو کنید ..."
              value={searchValue}
              onChange={handleSearch}
            />
          </span>

            

            <div id="searchbox" style={{display: searchValue!=="" ?  "block" : "none", marginTop:"0px"}}>

                    {/* load animation */}
                    {searchProductsLoading ? 
                        <div className="spinner" style={{marginBottom:"50px"}}></div>
                    : null}

                
            {allproducts.length>0 && showSearchbox && (

            <ul>

                    
                    {allproducts.map((product, index) => {
                        return (
                        <div className={"user" + index} key={index}>
                            <div id="suggestion-box" className="search-row" style={{display:"flex",direction:"rtl",alignItems:"center"}}
                            onClick={()=>navigate("/posts/"+product._id)}
                            >
                           
                           <span style={{display:"flex",direction:"rtl",alignItems:"center"}}>
                           <img style={{borderRadius:'10px'}} src={`${process.env.REACT_APP_BACK}/posts/${product.file}`}></img>
                           <p style={{marginRight:"20px" , fontSize:"13px"}}>{product.description.substring(0,100)}</p>
                           </span>
                           
                            </div>
                        </div>

                        
                        );
                        
                    })}

                    

                    

            </ul>


            )}

            {allproducts.length===0 && showSearchbox ?<span style={{marginTop:"20px"}}>  نتیجه ای یافت نشد </span>: null}

            </div>

            </div>
                

                <div id="riderSection">

                <h3 className="title">محصولات سوار  </h3>


                {riderPrsRows.map((row, rowIndex) => (
                    <div key={rowIndex} style={{ display: 'flex', width: '100%' , gap: '10px' , marginBottom:'10px'}}>
                      {row.map((product, index) => (
                        
                          <Link  key={index} style={{ width: desktop ?'50%' : "100%", display: desktop ? 'flex' : 'block'}}
                                className="other_articles" to={"/posts/"+product._id}>
                        <li>
                            <div>
                              
                                <img 
                                    src={product.fileType === "image" ?
                                      `${process.env.REACT_APP_BACK}/posts/${product.file}`
                                    : `${process.env.REACT_APP_BACK}/posters/poster_${product.file.replace(/\.[^/.]+$/, '')}.png`}
                                    alt="پستی از فلیکا، شبکه اجتماعی سوارکاری"
                                ></img>
                                {product.description.length>=50 ?
                                <h3 dangerouslySetInnerHTML={{ __html: product.description.substring(0, 50) + '...'  }} /> 
                                : 
                                <h3 dangerouslySetInnerHTML={{ __html: product.description}} /> 
                                }
                            </div>
                        </li>
                        </Link>
                        
                      ))}
                    </div>
                  ))}

                </div>


                <div id="horseSection">

                <h3 className="title">محصولات اسب </h3>

                {horsePrsRows.map((row, rowIndex) => (
                    <div key={rowIndex} style={{ display: 'flex', width: '100%' , gap: '10px' , marginBottom:'10px'}}>
                      {row.map((product, index) => (
                        
                          <Link  key={index} style={{ width: desktop ? '50%' : '100%', display: desktop ?'flex' : 'block'}}
                                className="other_articles" to={"/posts/"+product._id}>
                        <li>
                            <div>
                            <img 
                                    src={product.fileType === "image" ?
                                      `${process.env.REACT_APP_BACK}/posts/${product.file}`
                                    : `${process.env.REACT_APP_BACK}/posters/poster_${product.file.replace(/\.[^/.]+$/, '')}.png`}
                                    alt="پستی از فلیکا، شبکه اجتماعی سوارکاری"
                                ></img>
                                 {product.description.length>=50 ?
                                <h3 dangerouslySetInnerHTML={{ __html: product.description.substring(0, 50) + '...'  }} /> 
                                : 
                                <h3 dangerouslySetInnerHTML={{ __html: product.description}} /> 
                                }
                            </div>
                        </li>
                        </Link>
                        
                      ))}
                    </div>
                  ))}

                </div>

                <div id="tagsSection">

                <h3 className="title">برچسب ها</h3>
                    
                    
                    <div className="tags">
                    <ul className="section1">

                    
                    {fetchedtags.map((tag, index) => (
                    
                        

                        <Link  key={index} to={"/tags/"+tag.value}>
                        <li>
                            <Tag value={tag.value}></Tag>
                        </li>
                        </Link>

                    ))}

                    </ul>

                    </div>

                          <div>
                  <button 
                      style={{margin:"50px 30px 20px 30px"}} 
                      className="login-button"
                      onClick={()=>navigate("/tags")}
                      >
                          مشاهده همه تگ ها</button>
                    
                    </div>

                </div>

            </div>

           

        </div>
    );
}

export default WeblogSidebar1;